export enum EnvTypeENUM {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

export const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || EnvTypeENUM.development;
export const isDev = envType === EnvTypeENUM.development;
export const isProd = envType === EnvTypeENUM.production;
export const isStage = envType === EnvTypeENUM.staging;

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  maxRequestNumberOfAttempts: Number(process.env.REACT_APP_MAX_REQUESTS_ATTEMPTS) || 10,
  startYear: Number(process.env.REACT_APP_START_YEAR) || 2022,
};

export default config;
