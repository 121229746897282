import { createSlice } from '@reduxjs/toolkit';

import thunks from './clientSlice.thunks';
import type {
  ClientSearchesType,
  MonthSearchesType,
  PaginationDataType,
} from '../../api/api.types';

type InitialStateType = {
  allClientsSearches: Record<string, MonthSearchesType> | null;
  allClientsSearchesLoaded: boolean;
  clients: ClientSearchesType[];
  clientsPagination: PaginationDataType;
  clientsLoading: boolean;
  clientsUpdated: boolean;
};

const getInitialStore = (): InitialStateType => ({
  allClientsSearches: null,
  allClientsSearchesLoaded: false,
  clients: [],
  clientsPagination: {
    page: 1,
    take: 8,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  clientsLoading: false,
  clientsUpdated: false,
});

const clientSlice = createSlice({
  name: 'client',
  initialState: getInitialStore,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.getSearchesByYear.fulfilled, (store, { payload }) => {
      store.allClientsSearchesLoaded = true;
      store.allClientsSearches = payload;
    });
    builder.addCase(thunks.getSearchesByYear.rejected, (store) => {
      store.allClientsSearchesLoaded = false;
    });
    builder.addCase(thunks.getAllClientsWithSearches.pending, (store) => {
      store.clientsLoading = true;
    });
    builder.addCase(thunks.getAllClientsWithSearches.fulfilled, (store, { payload }) => {
      store.clientsLoading = false;
      store.clients = payload.clients;
      store.clientsPagination = payload.pagination;
    });
    builder.addCase(thunks.getAllClientsWithSearches.rejected, (store) => {
      store.clientsLoading = true;
    });
    builder.addCase(thunks.createClient.fulfilled, (store) => {
      store.clientsUpdated = true;
    });
    builder.addCase(thunks.createClient.rejected, (store) => {
      store.clientsUpdated = false;
    });
    builder.addCase(thunks.createClient.pending, (store) => {
      store.clientsUpdated = false;
    });
    builder.addCase(thunks.deleteClient.fulfilled, (store) => {
      store.clientsUpdated = true;
    });
    builder.addCase(thunks.deleteClient.rejected, (store) => {
      store.clientsUpdated = false;
    });
    builder.addCase(thunks.deleteClient.pending, (store) => {
      store.clientsUpdated = false;
    });
  },
});

export const clientSliceActions = clientSlice.actions;

export default clientSlice.reducer;
