const INTER = '"Inter", sans-serif';
const DIDACT_GOTHIC = '"Didact Gothic", sans-serif';

export const theme = {
  colors: {
    green1: '#4BC875',
    green2: '#536C5B',
    green3: '#D2EADE',
    green4: '#E9F9F4',
    grey2: '#8F9793',
    grey3: '#C3C2C7',
    grey4: '#E0E1E5',
    grey5: '#E7EBEC',
    grey6: '#EBF2F1',
    text1: '#182417',
    text2: '#9CA79E',
    white1: '#F8FAFC',

  },
  fonts: {
    Inter: INTER,
    Didact: DIDACT_GOTHIC,
  },
  typography: {
    appTitle: `
      font-family: ${DIDACT_GOTHIC};
      font-weight: 600;
      font-size: 27px;
      line-height: 34px;
      letter-spacing: 0.01em;
    `,
    signInText: `
      font-family: ${INTER};
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
    `,
    formInput: `
      font-family: ${INTER};
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em; 
    `,
    formButton: `
      font-family: ${INTER};
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.02em; 
    `,
    headerH1: `
      font-family: ${INTER};
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
    `,
    legend: `
      font-family: ${INTER};
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    `,
    tableHeadings: `
      font-family: ${INTER};
      font-weight: 600;
      font-size: 12px;
      line-height: 16.8px;
      letter-spacing: 0.01em;
    `,
    p1: `
      font-family: ${INTER};
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em; 
    `,
    h4: `
      font-family: ${INTER};
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    `,
    h3: `
      font-family: ${INTER};
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    `,
    h2: `
      font-family: ${INTER};
      font-weight: 700;
      font-size: 26px;
      line-height: 31.5px;
    `,
    textBold: `
      font-family: ${INTER};
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    `,
  },
};
