import http, { resetToken } from './http';
import storage from '../utils';
import type { IUser } from '../types/userType';
import { UrlPathENUM } from './constants';

const signIn = async (data: { email: string; password: string }) => {
  const response = await http.post<{
    user: IUser;
    tokens: { accessToken: string; refreshToken: string };
  }>(UrlPathENUM.AUTH_SIGN_IN, data);

  storage.accessToken.set(response.data.tokens.accessToken);
  storage.refreshToken.set(response.data.tokens.refreshToken);
  resetToken();
  return response;
};

export default {
  signIn,
};
