import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { routerConstants } from './constants';
import Protector from './Protector';

const SignInPage = React.lazy(
  () => import('../pages/SignInPage/SignInPage'),
);

const HomePage = React.lazy(
  () => import('../pages/HomePage/HomePage'),
);

export const router = createBrowserRouter([
  {
    path: routerConstants.signIn,
    element: (
      <Protector protectType="unprotected" redirectTo={{ unprotected: routerConstants.home }}>
        <SignInPage />
      </Protector>
    ),
  },
  {
    path: routerConstants.home,
    element: (
      <Protector protectType="protected" redirectTo={{ protected: routerConstants.signIn }}>
        <HomePage />
      </Protector>
    ),
  },
]);
