import { createAsyncThunk } from '@reduxjs/toolkit';

import type {
  ClientSearchesType,
  DaySearchesType,
  GetClientsSearchesResponseType,
  MonthSearchesType,
} from '../../api/api.types';
import clientApi from '../../api/clientApi';
import { showErrorMessage } from '../../utils/handleErrors';

const getSearchesByYear = createAsyncThunk<Record<string, MonthSearchesType>, { year: string }>(
  'client/getSearchesByYear',
  async (params: { year: string }, { rejectWithValue }) => {
    try {
      const response = await clientApi.getSearchesByYear(params);

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

const getClientSearchesByYear = createAsyncThunk<
  Record<string, MonthSearchesType>,
  { year: string; clientId: string }
>(
  'client/getClientSearchesByYear',
  async (params: { year: string; clientId: string }, { rejectWithValue }) => {
    try {
      const response = await clientApi.getSearchesByYear(params);

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

const getAllClientsWithSearches = createAsyncThunk<
  GetClientsSearchesResponseType,
  {
    page?: string;
    search?: string;
    sortBy?: string;
    order?: string;
  }
>(
  'client/getAllClientsWithSearches',
  async (
    params: { page?: string; search?: string; sortBy?: string; order?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await clientApi.getAllClientsWithSearches(params);

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

const createClient = createAsyncThunk(
  'client/createClient',
  async (alias: string, { rejectWithValue }) => {
    try {
      const response = await clientApi.createClient({ alias });

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

const deleteClient = createAsyncThunk(
  'client/deleteClient',
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await clientApi.deleteClientById(clientId);

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

const getClientByIdWithSearches = createAsyncThunk<
  ClientSearchesType,
  {
    clientId: string;
  }
>('client/getClientByIdWithSearches', async (params: { clientId: string }, { rejectWithValue }) => {
  try {
    const response = await clientApi.getAllClientsWithSearches(params);

    return response.data.clients[0] || null;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error);
  }
});

const getYearSearchesByClientId = createAsyncThunk<
  DaySearchesType[],
  {
    clientId: string;
    year: string;
    sortBy?: string;
    order?: string;
  }
>('client/getYearSearchesByClientId', async (options: { year: string; clientId: string }, { rejectWithValue }) => {
  try {
    const response = await clientApi.getYearSearchesByClientId(options);

    return response.data;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error);
  }
});

export default {
  getSearchesByYear,
  getClientSearchesByYear,
  getAllClientsWithSearches,
  createClient,
  deleteClient,
  getClientByIdWithSearches,
  getYearSearchesByClientId,
};
