import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../store/store';
import type { FinalPropsType } from './types';
import { routerConstants } from './constants';

const Protector: React.FC<FinalPropsType> = (props) => {
  const isAuth = useAppSelector(({ userState }) => userState.isAuthChecked);
  if (props.protectType === 'unprotected') {
    return props.children;
  }

  if (props.protectType === 'protected' && isAuth) {
    return props.children;
  }

  return <Navigate to={props.redirectTo[props.protectType] ?? routerConstants.signIn} />;
};

export default Protector;
