import config from '../config';

const sleep = (timeout: number) => {
  return new Promise((res) => {
    setTimeout(res, timeout);
  });
};

const getYearOptions = (): string[] => {
  const startYear = config.startYear;
  let currentYear = new Date().getFullYear();
  const years = [];
  while (currentYear >= startYear) {
    years.push(currentYear.toString());
    currentYear -= 1;
  }
  return years;
};

export default { sleep, getYearOptions };
