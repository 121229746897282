import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import { router } from './routes/Router';
import GlobalStyles from './styles/global';
import { theme } from './styles/theme';
import { useAppDispatch, useAppSelector } from './store/store';
import mainSliceThunks from './store/mainSlice/mainSlice.thunks';
import Loader from './ui/components/Loader/Loader';

const App: React.FC = () => {
  const isLoaded = useAppSelector(({ userState }) => userState.isLoaded);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(mainSliceThunks.authorize());
  }, [dispatch]);
  if (!isLoaded) {
    return <Loader />;
  }
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastContainer closeButton={false} />
      <React.Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </React.Suspense>
    </ThemeProvider>
  );
};

export default App;
