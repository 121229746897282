import { createAsyncThunk } from '@reduxjs/toolkit';

import utils from 'src/utils';
import authApi from 'src/api/authApi';
import type { IUser } from '../../types/userType';
import userApi from '../../api/userApi';
import { showErrorMessage } from '../../utils/handleErrors';

const authorize = createAsyncThunk<IUser | null>(
  'main/authorize',
  async (_, { rejectWithValue }) => {
    try {
      const storedAccessToken = utils.accessToken.get();
      if (!storedAccessToken) {
        throw new Error('No available tokens provided');
      }
      const response = await userApi.getMe();

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

const signIn = createAsyncThunk(
  'main/signIn',
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await authApi.signIn(data);

      return response.data;
    } catch (error) {
      showErrorMessage(error);
      return rejectWithValue(error);
    }
  },
);

export default {
  authorize,
  signIn,
};
