import { createSlice } from '@reduxjs/toolkit';

import thunks from './mainSlice.thunks';
import type { IUser } from '../../types/userType';

type InitialStateType = {
  user: IUser | null;
  isAuthChecked: boolean;
  isLoaded: boolean;
};

const getInitialStore = (): InitialStateType => ({
  user: null as IUser | null,
  isAuthChecked: false,
  isLoaded: false,
});

const mainSlice = createSlice({
  name: 'main',
  initialState: getInitialStore,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.authorize.fulfilled, (store, { payload }) => {
      store.isLoaded = true;
      store.isAuthChecked = true;
      store.user = payload;
    });
    builder.addCase(thunks.authorize.rejected, (store) => {
      store.isLoaded = true;
      store.isAuthChecked = false;
    });

    builder.addCase(thunks.signIn.fulfilled, (store, { payload }) => {
      store.isLoaded = true;
      store.user = payload.user;
      store.isAuthChecked = true;
    });
  },
});

export const mainSliceActions = mainSlice.actions;

export default mainSlice.reducer;
