import http from './http';
import type { IUser } from '../types/userType';
import { UrlPathENUM } from './constants';

const getMe = () => {
  return http.get<IUser>(UrlPathENUM.USER_ME);
};

export default {
  getMe,
};
