import axios from 'axios';
import type { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import type { ErrorResponseType } from '../api/http/http.types';

export const checkIsAxiosError = (err: Error | unknown): err is AxiosError<ErrorResponseType> => {
  return axios.isAxiosError(err);
};

export const checkIsValidationError = (
  err: Error | unknown,
): err is AxiosError<ErrorResponseType> => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 400;
};

export const checkIsNotFoundError = (
  err: Error | unknown,
): err is AxiosError<ErrorResponseType> => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 404;
};
export const checkIsConflictError = (
  err: Error | unknown,
): err is AxiosError<ErrorResponseType> => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 409;
};

export const checkIsUnauthorizedError = (
  err: Error | unknown,
): err is AxiosError<ErrorResponseType> => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 401;
};

export const showErrorMessage = (error: unknown) => {
  if (checkIsAxiosError(error)) {
    const errorMessage = error?.response?.data?.message || 'Something went wrong';
    toast.error(errorMessage);
  }
};
