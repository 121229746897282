import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    /* color: ${({ theme }) => theme.colors.text1}; */
  }
  .MuiMenu-root {
    li {
      ${({ theme }) => theme.typography.formInput}
    }
  }
`;
