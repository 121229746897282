import http from './http';
import { UrlPathENUM } from './constants';
import type {
  ClientType,
  CreateClientResponseType,
  DaySearchesType,
  GetClientsSearchesResponseType,
  MonthSearchesType,
} from './api.types';

const getSearchesByYear = async (params: { year: string; clientId?: string }) => {
  const response = await http.get<Record<string, MonthSearchesType>>(UrlPathENUM.CLIENT_SEARCH, {
    params,
  });

  return response;
};

const getAllClientsWithSearches = async (params: {
  page?: string;
  search?: string;
  sortBy?: string;
  order?: string;
  clientId?: string;
}) => {
  const response = await http.get<GetClientsSearchesResponseType>(UrlPathENUM.CLIENT, {
    params,
  });

  return response;
};

export const createClient = async (data: { alias: string }) => {
  const response = await http.post<CreateClientResponseType>(UrlPathENUM.CLIENT, data);

  return response;
};

const deleteClientById = async (clientId: string) => {
  const response = await http.delete<ClientType>(`${UrlPathENUM.CLIENT}/${clientId}`);

  return response;
};

const getYearSearchesByClientId = async (options: {
  year: string;
  clientId: string;
  sortBy?: string;
  order?: string;
}) => {
  const params = {
    year: options.year,
    sortBy: options.sortBy || undefined,
    order: options.order || undefined,
  };
  const response = await http.get<DaySearchesType[]>(
    `${UrlPathENUM.CLIENT}/${options.clientId}/search`,
    {
      params,
    },
  );

  return response;
};

export default {
  getSearchesByYear,
  getAllClientsWithSearches,
  createClient,
  deleteClientById,
  getYearSearchesByClientId,
};
